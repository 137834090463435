import api from './api';

// Authentication
export const login = async (credentials) => {
  try {
    const response = await api.post('/api/login', credentials);
    return response;
  } catch (error) {
    console.error('Login error details:', {
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers
    });
    throw error;
  }
};

// File operations
export const uploadFile = async (formData) => {
  console.log("uploadFile called with formData:", {
    hasFile: formData.has('file'),
    entries: Array.from(formData.entries()).map(([key, value]) => ({
      key,
      type: value instanceof File ? 'File' : typeof value,
      size: value instanceof File ? value.size : null
    }))
  });
  
  return await api.post('/api/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 30000,
    maxContentLength: Infinity,
    maxBodyLength: Infinity
  });
};

// Queries
export const queryDocuments = async (question) => {
  return await api.post('/api/query', { user: question });
};

// register a new user
export const register = async (userData) => {
  try {
    const response = await api.post('/api/register', userData);
    console.log('Register response:', response);
    return response;
  } catch (error) {
    console.error('Registration error details:', {
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers
    });
    throw error;
  }
};

// User profile
export const getUserProfile = async () => {
  try {
    const response = await api.get('/api/user/profile');
    return response;
  } catch (error) {
    console.error('Get user profile error:', error);
    throw error;
  }
};

// Add onboarding endpoint
export const completeOnboarding = async () => {
  try {
    const response = await api.post('/api/onboarding', {
      option: 'email',
      completed: true
    });
    return response;
  } catch (error) {
    console.error('Onboarding error:', error);
    throw error;
  }
};

// Add these new endpoints alongside your existing ones
export const forgotPassword = async (data) => {
  try {
    const response = await api.post('/api/forgot-password', data);
    return response;
  } catch (error) {
    console.error('Forgot password error details:', {
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers
    });
    throw error;
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await api.post('/api/reset-password', data);
    return response;
  } catch (error) {
    console.error('Reset password error details:', {
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers
    });
    throw error;
  }
};

// Add other endpoints as needed...
