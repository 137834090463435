import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserProfile, completeOnboarding } from '../services/endpoints';

function Onboarding() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await getUserProfile();
      setUserEmail(response.data.goffer_email);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError('Unable to fetch user information');
    }
  };

  const handleContinue = async () => {
    setError('');
    try {
      const response = await completeOnboarding();
      if (response.status === 200) {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Onboarding error:', error.response?.data?.error || error.message);
      setError(error.response?.data?.error || 'An error occurred during onboarding');
    }
  };

  return (
    <div className="onboarding-container">
      <h2>Welcome to Goffer AI</h2>
      
      {error && <div className="error-message">{error}</div>}
      
      <div className="onboarding-content">
        <div className="info-section">
          <h3 className="text-left">Goffer, your personal AI assistant is ready!</h3><br/>
          <p className="text-left">
            Congratulations! You've successfully set up your Goffer AI assistant. 
            Moving forward, your assistant will automatically index and analyze any documents 
            sent to your dedicated Goffer email:
          </p>
          
          <div className="email-box">
            <i className="fas fa-envelope"></i>
            <strong>Your Goffer Email:</strong>
            <code>{userEmail || 'Loading...'}</code>
          </div>
        </div>

        <div className="features-section">
          <h3>How to Use Goffer AI</h3>
          
          <div className="feature-item">
            <i className="fas fa-paper-plane"></i>
            <div>
              <h4>Forward or Send Emails</h4>
              <p>Simply forward any emails with attachments to your Goffer email address. Your AI assistant will process all documents automatically.</p>
            </div>
          </div>

          <div className="feature-item">
            <i className="fas fa-upload"></i>
            <div>
              <h4>Manual Upload</h4>
              <p>Use the upload button in the dashboard to manually add documents to your workspace.</p>
            </div>
          </div>

          <div className="feature-item">
            <i className="fas fa-comments"></i>
            <div>
              <h4>Chat with Your Documents</h4>
              <p>Once processed, you can ask questions and get insights about your documents using our AI-powered chat interface.</p>
            </div>
          </div>
        </div>

        <button 
          onClick={handleContinue}
          className="continue-button"
        >
          Continue to Dashboard
        </button>
      </div>
    </div>
  );
}

export default Onboarding;
