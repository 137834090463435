import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as endpoints from '../services/endpoints'; 

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState(''); // Add this state for error handling

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Clear any previous errors
    const formData = new FormData(event.target);
    const credentials = {
      email: formData.get('email'),
      password: formData.get('password')
    };

    try {
      const response = await endpoints.login(credentials);
      if (response.data?.access_token) {
        localStorage.setItem('jwt_token', response.data.access_token);
        navigate('/dashboard');
      } else {
        setError('Invalid response from server');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(error.response?.data?.message || 'Login failed. Please check your credentials.');
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="w-[400px] bg-white rounded-lg p-8 shadow-sm">
        <h2 className="text-[#1980e6] text-base font-medium text-center">Log in to Goffer AI</h2>
        <p className="text-[#111418] text-sm text-center mt-1 mb-6">
          Access the power of AI to manage your documents effortlessly.
        </p>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            {error}
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="text-sm text-[#111418]">Email</label>
            <input 
              name="email"
              type="email" 
              className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg" 
              required
              data-testid="email-input"
            />
          </div>
          
          <div>
            <label className="text-sm text-[#111418]">Password</label>
            <input 
              name="password"
              type="password" 
              className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg" 
              required
              data-testid="password-input"
            />
          </div>

          <div className="flex justify-end">
            <a 
              href="/forgot-password" 
              className="text-sm text-[#1980e6] hover:underline"
            >
              Forgot password?
            </a>
          </div>

          <button 
            type="submit" 
            className="w-full bg-[#1980e6] text-white rounded-lg py-2"
            data-testid="login-submit-button"
          >
            Log in
          </button>

          <button 
            type="button"
            className="w-full bg-[#dc4e41] text-white rounded-lg py-2"
          >
            Log in with Google
          </button>

          <button 
            type="button"
            className="w-full bg-[#1980e6] text-white rounded-lg py-2"
          >
            Log in with Microsoft
          </button>

          <p className="text-center text-sm">
            Don't have an account? <a href="/register" className="text-[#1980e6]">Register here</a>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Login;
