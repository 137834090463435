import React from 'react';
import { useNavigate } from 'react-router-dom';

function AuthenticatedNavbar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    console.log('Current token before logout:', localStorage.getItem('jwt_token'));
    localStorage.removeItem('jwt_token');
    console.log('Token after removal:', localStorage.getItem('jwt_token'));
    navigate('/login', { replace: true });
  };

  return (
    <nav className="flex items-center justify-between whitespace-nowrap border-b border-solid border-b-[#f0f2f4] px-4 md:px-10 py-3">
      <div className="flex items-center gap-2 md:gap-4">
        <div className="text-[#111418] text-base md:text-lg font-bold">Goffer AI</div>
      </div>
      <div className="flex items-center gap-2 md:gap-4">
        <button 
          onClick={handleLogout}
          className="text-[#111418] hover:text-[#1980e6] text-sm font-medium px-3 py-2"
        >
          Log out
        </button>
      </div>
    </nav>
  );
}

export default AuthenticatedNavbar;

