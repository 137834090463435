import React from 'react';

function Features() {
  return (
    <div className="relative flex min-h-screen flex-col bg-white">
      <div className="flex flex-1 justify-center">
        <div className="w-full max-w-[1200px] px-4 md:px-10 lg:px-20 py-8 md:py-12">
          {/* Header Section */}
          <div className="flex flex-col gap-6 mb-12">
            <h1 className="text-[#111418] text-3xl md:text-4xl lg:text-5xl font-bold leading-tight">
              Features
            </h1>
            <p className="text-[#637588] text-base md:text-lg max-w-[800px]">
              Discover how Goffer AI can transform your document processing and management
            </p>
          </div>

          {/* Features Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            {/* Feature Card - Document Processing */}
            <div className="flex flex-col gap-4 p-6 bg-white rounded-xl border border-[#f0f2f4]">
              <h2 className="text-[#111418] text-xl font-bold leading-tight">Upload Documents</h2>
              <p className="text-[#637588] text-base">
                Easily upload documents directly to Goffer AI for quick processing. Whether it's through your desktop, mobile device, or cloud storage, you can seamlessly integrate your files into the platform.
              </p>
              <div className="bg-[#f0f2f4] rounded-lg p-6 mt-auto">
                <p className="text-[#111418] font-medium mb-2">How It Works:</p>
                <ul className="text-[#637588] list-disc list-inside space-y-2">
                  <li>Upload documents in various formats</li>
                  <li>Automatic content analysis</li>
                  <li>Smart organization and tagging</li>
                </ul>
              </div>
            </div>

            {/* Feature Card - AI Analysis */}
            <div className="flex flex-col gap-4 p-6 bg-white rounded-xl border border-[#f0f2f4]">
              <h2 className="text-[#111418] text-xl font-bold leading-tight">AI-Powered Analysis</h2>
              <p className="text-[#637588] text-base">
                Let our advanced AI analyze your documents to extract key information, identify patterns, and provide valuable insights automatically.
              </p>
              <div className="bg-[#f0f2f4] rounded-lg p-6 mt-auto">
                <p className="text-[#111418] font-medium mb-2">How It Works:</p>
                <ul className="text-[#637588] list-disc list-inside space-y-2">
                  <li>Automatic content analysis</li>
                  <li>Extraction of key information</li>
                  <li>Pattern identification</li>
                </ul>
              </div>
            </div>

            {/* Feature Card - Chat Assistant */}
            <div className="flex flex-col gap-4 p-6 bg-white rounded-xl border border-[#f0f2f4]">
              <h2 className="text-[#111418] text-xl font-bold leading-tight">Chat Assistant</h2>
              <p className="text-[#637588] text-base">
                Chat with your AI assistant directly from the platform to get summaries, create tasks, or ask questions about your documents.
              </p>
              <div className="bg-[#f0f2f4] rounded-lg p-6 mt-auto">
                <p className="text-[#111418] font-medium mb-2">How It Works:</p>
                <ul className="text-[#637588] list-disc list-inside space-y-2">
                  <li>Start a chat session with your AI assistant</li>
                  <li>Ask questions about your documents</li>
                  <li>Request tasks to be created</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
