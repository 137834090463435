import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000',
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(request => {
  const token = localStorage.getItem('jwt_token');
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  
  // Only set Content-Type if not a multipart request
  if (request.data instanceof FormData) {
    delete request.headers['Content-Type'];
  }
  
  console.log('Starting Request:', {
    url: request.url,
    baseURL: request.baseURL,
    method: request.method,
    data: request.data,
    headers: request.headers
  });
  return request;
});

api.interceptors.response.use(
  response => {
    console.log('Response:', {
      status: response.status,
      data: response.data,
      headers: response.headers
    });
    return response;
  },
  error => {
    console.error('API Error:', {
      message: error.message,
      response: error.response?.data,
      config: error.config
    });
    return Promise.reject(error);
  }
);

export default api;
