import React, { useState } from 'react';
import * as endpoints from '../services/endpoints';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await endpoints.forgotPassword({ email });
      setMessage(response.data.message);
      setError('');
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred');
      setMessage('');
    }
  };

  return (

      <div className="flex min-h-screen items-center justify-center">
        <div className="w-[400px] bg-white rounded-lg p-8 shadow-sm">
          <h2 className="text-[#1980e6] text-base font-medium text-center">Reset Password</h2>
          <p className="text-[#111418] text-sm text-center mt-1 mb-6">
            Enter your email address and we'll send you instructions to reset your password.
          </p>

        {message && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
            {message}
          </div>
        )}

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="text-sm text-[#111418]">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
              required
            />
          </div>

          <button 
            type="submit" 
            className="w-full bg-[#1980e6] text-white rounded-lg py-2"
          >
            Send Reset Instructions
          </button>

          <p className="text-center text-sm">
            <a href="/login" className="text-[#1980e6]">Back to Login</a>
          </p>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword; 