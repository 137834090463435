import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import * as endpoints from '../services/endpoints';


function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const token = searchParams.get('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await endpoints.resetPassword({
        token,
        password
      });
      
      setMessage('Password reset successful! Redirecting to login...');
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred');
    }
  };

  if (!token) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="w-[400px] bg-white rounded-lg p-8 shadow-sm">
          <p className="text-red-600">Invalid reset link</p>
        </div>
      </div>
    );
  }

  return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="w-[400px] bg-white rounded-lg p-8 shadow-sm">
          <h2 className="text-[#1980e6] text-base font-medium text-center">Reset Password</h2>
          <p className="text-[#111418] text-sm text-center mt-1 mb-6">
            Enter your new password below.
          </p>

          {message && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
              {message}
            </div>
          )}

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="new-password" className="text-sm text-[#111418]">
                New Password
              </label>
              <input
                id="new-password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg" 
                required
              />
            </div>

            <div>
              <label htmlFor="confirm-password" className="text-sm text-[#111418]">
                Confirm New Password
              </label>
              <input
                id="confirm-password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg" 
                required
              />
            </div>

            <button 
              type="submit" 
              className="w-full bg-[#1980e6] text-white rounded-lg py-2"
            >
              Reset Password
            </button>
          </form>
        </div>
      </div>
  );
}

export default ResetPassword; 