import React, { useState, useRef, useEffect } from 'react';
import { queryDocuments } from '../services/endpoints';

const Query = () => {
  const [question, setQuestion] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  useEffect(() => {
    if (!isLoading) {
      inputRef.current?.focus();
    }
  }, [isLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!question.trim() || isLoading) return;

    const userMessage = {
      sender: 'You',
      content: question,
      type: 'user'
    };

    setChatHistory(prev => [...prev, userMessage]);
    setIsLoading(true);

    try {
      const response = await queryDocuments(question);
      
      const aiMessage = {
        sender: 'AI',
        content: response.data.answer || response.data.message || 'Sorry, I could not process your question.',
        type: 'ai'
      };

      setChatHistory(prev => [...prev, aiMessage]);
    } catch (error) {
      console.error('Query error:', error);
      
      const errorMessage = {
        sender: 'AI',
        content: error.response?.data?.message || error.response?.data?.error || 'Sorry, an error occurred.',
        type: 'error'
      };

      setChatHistory(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
      setQuestion('');
      inputRef.current?.focus();
    }
  };

  const copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(null);
      }, 2000); // Reset after 2 seconds
    });
  };

  return (
    <div>
      <div className="chat-container">
        <div className="chat-messages">
          {chatHistory.map((message, index) => (
            <div key={index} className={`chat-message ${message.type}-message`}>
              <div className="message-sender">{message.sender}:</div>
              <div className="message-content">{message.content}</div>
              <button 
                className="copy-button" 
                onClick={() => copyToClipboard(message.content, index)}
                title="Copy to clipboard"
              >
                <i className={`fas ${copiedIndex === index ? 'fa-check text-success' : 'fa-copy'}`}></i>
              </button>
            </div>
          ))}
          {isLoading && (
            <div className="chat-message ai-message">
              <div className="message-sender">AI:</div>
              <div className="message-content">
                <i className="fas fa-spinner fa-spin"></i> Thinking...
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>

      <form className="query-form" onSubmit={handleSubmit}>
        <div className="input-button-wrapper">
          <input 
            ref={inputRef}
            type="text" 
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Ask a question about your documents" 
            required 
            disabled={isLoading}
          />
          <button type="submit" className="btn btn-primary" disabled={isLoading || !question.trim()}>
            {isLoading ? (
              <>
                <i className="fas fa-spinner fa-spin"></i> Sending...
              </>
            ) : (
              <>
                <i className="fas fa-paper-plane"></i> Ask AI
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Query;
