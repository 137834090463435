import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="relative border-b border-[#f0f2f4] bg-white z-50">
      <div className="flex items-center justify-between px-4 py-4">
        <Link to="/" className="flex items-center gap-2">
          <div className="text-[#111418] text-lg font-bold">Goffer AI</div>
        </Link>
        <button 
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="block lg:hidden rounded-lg p-2 text-gray-600 hover:bg-gray-100"
          aria-label="Menu"
        >
          {isMenuOpen ? (
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          )}
        </button>
        <div className="hidden lg:flex items-center gap-8">
          <Link to="/features" className="text-[#111418] text-sm font-medium hover:text-[#637588]">Features</Link>
          <Link to="/use-cases" className="text-[#111418] text-sm font-medium hover:text-[#637588]">Use Cases</Link>
          <Link to="/blog" className="text-[#111418] text-sm font-medium hover:text-[#637588]">Blog</Link>
          <div className="flex gap-2">
            <Link to="/login">
              <button className="px-4 py-2 bg-[#f0f2f4] text-[#111418] rounded-xl text-sm font-bold">
                Log in
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div 
        className={`
          fixed left-0 right-0 top-[57px] bg-white border-b border-[#f0f2f4] 
          transform transition-transform duration-300 ease-in-out lg:hidden
          shadow-lg z-40 ${isMenuOpen ? 'translate-y-0' : '-translate-y-full'}
        `}
        style={{
          visibility: isMenuOpen ? 'visible' : 'hidden',
          transitionProperty: 'transform, visibility',
          transitionDuration: '300ms',
          transitionTimingFunction: 'ease-in-out',
        }}
      >
        <div className="flex flex-col p-4 space-y-4">
          <Link 
            to="/features" 
            className="text-[#111418] text-sm font-medium hover:text-[#637588] py-2"
            onClick={() => setIsMenuOpen(false)}
          >
            Features
          </Link>
          <Link 
            to="/use-cases" 
            className="text-[#111418] text-sm font-medium hover:text-[#637588] py-2"
            onClick={() => setIsMenuOpen(false)}
          >
            Use Cases
          </Link>
          <Link 
            to="/blog" 
            className="text-[#111418] text-sm font-medium hover:text-[#637588] py-2"
            onClick={() => setIsMenuOpen(false)}
          >
            Blog
          </Link>
          <div className="pt-4 border-t border-[#f0f2f4]">
            <Link 
              to="/login" 
              className="block"
              onClick={() => setIsMenuOpen(false)}
            >
              <button className="w-full px-4 py-2 bg-[#f0f2f4] text-[#111418] rounded-xl text-sm font-bold">
                Log in
              </button>
            </Link>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          style={{ top: '57px' }}
          onClick={() => setIsMenuOpen(false)}
        />
      )}
    </nav>
  );
};

export default Navbar;
